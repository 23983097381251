<template>
  <div>
    <v-row align="center">
      <v-col cols="auto" class="pr-12">
        <h2>Golfers</h2>
      </v-col>
      <v-col>
        <v-row align="center" justify="end" no-gutters>
          <v-btn
            small
            depressed
            dark
            @click="$refs.createGolferForm.openForm()"
          >
            <v-icon left>mdi-plus</v-icon>Add New
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
      :headers="tableHeaders"
      :items="tour.customers"
      no-data-text="There are no golfers found"
    >
      <template v-slot:item.link_type="{ item }">
        {{
          item.pivot.link_type.charAt(0).toUpperCase() +
          item.pivot.link_type.slice(1)
        }}
      </template>
      <template v-slot:item.golfer="{ item }">
        {{ item.pivot.is_golfer ? "Golfer" : "Non-Golfer" }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              depressed
              color="blue lighten-4 blue--text"
              v-on="on"
              @click="$refs.editGolferForm.openForm(item)"
            >
              <v-icon x-small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.id !== tour.lead_contact_id">
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              depressed
              color="red lighten-4 red--text"
              class="ml-2"
              v-on="on"
              @click="openRemoveGolfer(item)"
            >
              <v-icon x-small>mdi-link-off</v-icon>
            </v-btn>
          </template>
          <span>Remove From Tour</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <CreateGolferForm ref="createGolferForm" />
    <EditGolferForm ref="editGolferForm" />
    <v-dialog v-model="removeGolfer.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Remove Golfer from Tour</v-card-title>
        <v-card-text
          >Are you sure you wish to remove
          {{ removeGolfer.golfer.first_name }} from this tour?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetRemoveGolfer"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="removeGolfer.loading"
            @click="saveRemoveGolfer"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CreateGolferForm from "../components/CreateGolferForm";
import EditGolferForm from "../components/EditGolferForm";

export default {
  components: {
    CreateGolferForm,
    EditGolferForm,
  },

  data() {
    return {
      tableHeaders: [
        { text: "Full Name", value: "full_name" },
        { text: "Email", value: "email" },
        { text: "Link Type", value: "link_type" },
        { text: "Golfer/Non-Golfer", value: "golfer" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      removeGolfer: {
        dialog: false,
        golfer: {},
        loading: false,
      },
    };
  },

  computed: {
    tour() {
      return this.$store.state.drum.tours["tour"];
    },
  },

  methods: {
    openRemoveGolfer: function (golfer) {
      this.removeGolfer.golfer = golfer;
      this.removeGolfer.dialog = true;
    },

    resetRemoveGolfer: function () {
      this.removeGolfer.dialog = false;
      this.removeGolfer.golfer = {};
      this.removeGolfer.loading = false;
    },

    saveRemoveGolfer: function () {
      this.removeGolfer.loading = true;

      this.$store
        .dispatch("drum/tours/deleteGolfer", {
          appId: this.$route.params.id,
          tourId: this.$route.params.tourId,
          golferId: this.removeGolfer.golfer.id,
        })
        .then(() => {
          this.resetRemoveGolfer();
        })
        .catch(() => {
          this.removeGolfer.loading = false;
        });
    },
  },
};
</script>